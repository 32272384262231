import React from "react";
import Navbar from "../../Section/Navbar/Navbar";
import Hero from "../../Section/Hero/Hero";
import About from "../../Section/About/About";
import Promo from "../../Section/Promo/Promo";
import Newlaunching from "../../Section/Newlaunching/Newlaunching";
import Kawasan from "../../Section/Future/future";
import Viola from "../../Section/Viola/Viola";
import Regia from "../../Section/Regia/Regia";
import Jasmia from "../../Section/Jasmia/Jasmia";
import Gading from "../../Section/Gading/Gading";
import Surrounding from "../../Section/Surrounding/Surrounding";
import Footer from "../../Section/Footer/Footer";
import ScrollToHashElement from "./ScrollToHash";

const Home = () => {
  return (
    <div>
      <ScrollToHashElement />
      <Navbar />
      <Hero />
      <About />
      <Promo />
      <Newlaunching />
      <Viola />
      <Regia />
      <Jasmia />
      <Gading />
      <Kawasan />
      <Surrounding />
      <Footer />
    </div>
  );
};

export default Home;
